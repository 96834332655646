<script>
import {mapGetters, mapState} from "vuex";
import {mdiLogout, mdiAccount, mdiTable} from "@mdi/js";
import {getImage} from "@/services/helpers";
export default {
  name: "App",
  components: {},
  data: function () {
    return {
      mdiLogout,
      mdiAccount,
      mdiTable
    };
  },
  computed: {
    ...mapState("App", { isRouterLoading: 'isRouterLoading' }),
    ...mapGetters("App", { isScreenSmall: 'isScreenSmall' }),
    ...mapGetters("User", {
      isLoggedIn: 'isLoggedIn',
      userRole: 'getRole'
    }),
  },
  created () {
    this.onResize();
  },
  methods: {
    getImage,
    onResize () {
      this.$store.commit("App/MENUS", false);
      setImmediate(() => {
        this.$store.commit("App/ON_RESIZE");
        this.$store.commit("App/MENUS", true);
      });
    },
    logout() {
      this.$auth.logout()
    }
  },
}
</script>

<template>
  <v-app v-resize="onResize">
    <v-app-bar
      app
      absolute
      flat
      min-height="56px"
      max-height="64px"
      color="white"
      style="border-bottom: #d2d2d7 solid 2px !important;"
    >
      <router-link to="/">
        <v-img
          alt="VSM Service"
          :src="getImage('logo.png')"
          max-width="120"
          contain
        />
      </router-link>
      <v-spacer />
      <!--      <v-btn-->
      <!--        v-if="userRole === 'admin' && !$route.fullPath.includes('admin')"-->
      <!--        icon-->
      <!--        @click="$router.push('/admin')"-->
      <!--      >-->
      <!--        <v-icon>-->
      <!--          {{ mdiTable }}-->
      <!--        </v-icon>-->
      <!--      </v-btn>-->
      <template v-if="userRole === 'admin' && !$route.fullPath.includes('auth') && $route.name !== 'Start'">
        <v-btn
          v-if="isLoggedIn"
          icon
          @click="logout"
        >
          <v-icon>
            {{ mdiLogout }}
          </v-icon>
        </v-btn>
        <v-btn
          v-else
          icon
          @click="$router.push('/auth')"
        >
          <v-icon>
            {{ mdiAccount }}
          </v-icon>
        </v-btn>
      </template>
    </v-app-bar>
    <v-main v-if="isRouterLoading">
      <v-overlay>
        <v-progress-circular
          indeterminate
          size="64"
        />
      </v-overlay>
    </v-main>
    <v-main v-else>
      <router-view
        :key="$route.fullPath"
        name="content"
      />
    </v-main>
  </v-app>
</template>