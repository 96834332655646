const routes =
    [
        {
            path: "/",
            name: "Start",
            components: {
                content: () => import("@/views/Start"),
            },
            meta: {
                title: "pages.start.title",
                public: true,
            }
        },
        {
            path: "/questionnaire",
            name: "Questionnaire",
            components: {
                content: () => import("@/views/Questionnaire"),
            },
            meta: {
                title: "pages.questionnaire.title",
                public: true,
            }
        },
        {
            path: "/expired",
            name: "Expired",
            components: {
                content: () => import("@/views/Expired"),
            },
            meta: {
                title: "pages.expired.title",
                public: true,
            }
        },
        {
            path: "/finished",
            name: "Finished",
            components: {
                content: () => import("@/views/Finished"),
            },
            meta: {
                title: "pages.expired.title",
                public: true,
            }
        },
        {
            path: "/auth",
            name: "Auth",
            components: {
                content: () => import("@/views/Auth"),
            },
            meta: {
                title: "pages.auth.title",
                public: true,
            }
        },
        {
            path: "/admin",
            name: "Admin",
            components: {
                content: () => import("@/views/Statistics.vue"),
            },
            meta: {
                title: "pages.admin.title",
                public: false,
            }
        },
        {
            path: "/admin/auth",
            name: "Admin Auth",
            components: {
                content: () => import("@/views/AdminAuth"),
            },
            meta: {
                title: "pages.auth.title",
                public: true,
            }
        },
        {
            path: "/404",
            name: "NotFound",
            components: {
                content: () => import("@/views/404"),
            },
            meta: {
                title: "pages.404.title",
                public: true,
            }
        },
        {
            path: "*",
            redirect: "/404"
        }
    ];

export default routes