import ApiSecure from "@/services/api/api-secure";
import Api from "@/services/api/api";
import AuthService from "@/services/auth.service";

const initialState = () => ({
    description: null,
    fieldsSchema: [],
    secureType: null,
    nextTaskId: null,
    taskId: null,
    type: null,
    img: null,
    fields: [],
    showResults: false,
});


/* Module .store.js */


// VUEX STATE
const state = initialState();


// VUEX GETTERS
const getters = {
    getType: (state) => state.type,
    getFieldsSchema: (state) => state.fieldsSchema,
    getSecureType: (state) => state.secureType,
    getDescription: (state) => state.description,
    getTaskId: (state) => state.taskId,
    getNextTaskId: (state) => state.nextTaskId,
    getFields: (state) => state.fields,
    getTaskImg: (state) => state.img,
    getShowResults: (state) => state.showResults,
};


// VUEX ACTIONS
const actions = {
    reset({commit}) {
        commit('RESET');
    },
    async task({state, commit}) {
        commit("SET_TASK", await Api.get(`common/task/${state.nextTaskId}/`))
    },
    async taskSecure({state, commit}) {
        commit("SET_TASK", await ApiSecure.get(`common/task/${state.nextTaskId}/`))
    },
    async previousTask({state, commit, dispatch}) {
        const { id, secureType } = await Api.get(`common/task/${state.taskId}/previous/`);
        commit("SET_NEXT_TASK", id);
        if (secureType==="common") await dispatch("task");
        if (secureType==="secure") await dispatch("taskSecure");
    },
    async previousTaskSecure({state, commit, dispatch}) {
        const { id, secureType } = await ApiSecure.get(`common/task/${state.taskId}/previous/`);
        commit("SET_NEXT_TASK", id)
        if (secureType==="common") await dispatch("task")
        if (secureType==="secure") await dispatch("taskSecure")
    },
    async completeTask({state, commit, dispatch}) {
        let { id, secureType } = await Api.post(`common/task/complete/`, {
            fields: state.fields,
            taskId: state.taskId,
        })
        commit("SET_NEXT_TASK", id)
        if (secureType==="common") await dispatch("task")
        if (secureType==="secure") await dispatch("taskSecure")
    },
    async completeTaskFirst({state, commit, dispatch}, recaptchaToken) {
        let { id, secureType, access_token, refresh_token } = await Api.post(`common/task/complete_first/`, {
            fields: state.fields,
            taskId: 1,
            recaptchaToken: recaptchaToken
        })
        commit("SET_NEXT_TASK", id)
        await AuthService.onLoginSuccess({
            access: access_token,
            accessExpiresIn: 7200,
            refresh: refresh_token,
            refreshExpiresIn: 1209600})
        if (secureType==="common") await dispatch("task")
        if (secureType==="secure") await dispatch("taskSecure")
    },
    async completeTaskSecure({state, commit, dispatch}) {
        const { id, secureType } = await ApiSecure.post(`common/task/complete/`, {
            fields: state.fields,
            taskId: state.taskId,
        })
        commit("SET_NEXT_TASK", id)
        if (secureType==="common") await dispatch("task")
        if (secureType==="secure") await dispatch("taskSecure")
    },
    async firstTask({commit}) {
        commit("SET_TASK", await Api.get(`common/task/first/`))
    },
    async userTask({commit}) {
        commit("SET_TASK", await ApiSecure.get(`/user/task/`))
    }
};


// VUEX MUTATIONS
const mutations = {
    RESET(state) {
        const newState = initialState();
        Object.keys(newState).forEach(key => {
            state[key] = newState[key]
        });
    },
    SET_SHOW_RESULTS(state, value) {
        state.showResults = value
    },
    SET_TASK(state, {description, secureType, taskId, type, fieldsSchema, img}) {
        state.fieldsSchema = fieldsSchema
        state.description = description
        state.secureType = secureType
        state.taskId = taskId
        state.img = img
        state.type = type
        state.fields = []
        fieldsSchema.forEach((schema) => state.fields.push({
            fieldId: schema.fieldId,
            value: schema.type === "nan" ? "nan fill value": null
        }))
    },
    SET_FIELDS(state, {index, field, multipleForms, multipleFormsIndex}) {
        let newField
        if (multipleForms) {
            newField = {...state.fields[index]}
            if (Array.isArray(newField.value)) {
                if (multipleFormsIndex < newField.value.length) {
                    newField.value[multipleFormsIndex] = field.value
                }
                else {
                    newField.value.push(field.value)
                }
            } else {
                newField.value = new Array(multipleFormsIndex+1)
                newField.value[multipleFormsIndex] = field.value
            }
        } else {
            newField = field
        }
        state.fields.splice(index, 1, newField)
    },
    SET_NEXT_TASK(state, payload) {
        state.nextTaskId = payload
    },
    DELETE_FORM(state, payload) {
        for (let i = 0; i < state.fields.length; i++) {
            let value = state.fields[i].value
            if (Array.isArray(value) && payload < value.length)
                value.splice(payload, 1)
        }
    },
};


export default {
    state,
    getters,
    actions,
    mutations
};